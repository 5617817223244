@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
}
input,
select {
  width: 100%;
  padding: 15px;
  height: 50px;
  font-size: 1rem;
  font-weight: 300;
  border-radius: 0.5rem !important;
  border: 1px solid rgba(255, 255, 255, 10%);
  background-color: theme("colors.primary.600");
}
input:focus,
select:focus {
  border-color: theme("colors.secondary");
}

.submitButton {
  display: grid;
  width: 100%;
  height: 50px;
  /* border-width: 1px; */
  border-radius: 0.5rem !important;
  background-color: theme("colors.primary.500");
  color: theme("colors.white");
}
.submitButton:hover {
  background: theme("colors.secondary");
  border-color: theme("colors.secondary-light");
}
.submitButton:disabled {
  background: theme("colors.primary-light");
  border-color: theme("colors.secondary-light");
}

th,
td,
tr {
  padding: 10px;
}
td:first-child {
  font-weight: 700;
}
.bg {
  background-color: theme("colors.primary.500");
}
.error {
  padding: 5px;
  color: theme("colors.secondary");
}
